import { Link } from "react-router-dom";
import { LayFooter } from "./lay.footer";
import { LayHeaderPublic } from "./lay.header.public";

export function Page404() {
    return <>
        <LayHeaderPublic buttons={<></>} />

        <div className="container">
            <section className="section" style={{minHeight: '16rem'}}>
                <h1 className="title">Page 404</h1>
                <p className="subtitle">not found page</p>
                <Link to="/">Нүүр худас</Link>
            </section>
        </div>


        <LayFooter />
    </>
}