import "./style.scss";

interface IProps {
    box?: boolean;

    color?: 'green' | 'white'
}

export function Loader({ box, color }: IProps) {
    const msg = <div className={`lds-facebook ${color || ''}`}><div></div><div></div><div></div></div>;

    if (box) {
        return <div className="loader-is-boxed">{msg}</div>;
    }
    return <div className="loader-is-inline">{msg}</div>;
}