import { AppManager, useAppManager } from './manager';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { useState, Suspense, lazy, useEffect } from 'react';
import { Page404 } from './pages/page.404';
import { PageLogout } from './pages/logout';



import { Loader } from './comps/loader';
import { RoutePrivate } from './comps/route.private';
import { Dtimain_loginBySession } from './gen/dti/main/login.by.session';
import { ModalBasic, useModalManager } from '@napp-ui/modal';
import { Dtimain_Message } from './gen/dti/main/message';
import DetectRTC from 'detectrtc';



const PageHome = lazy(() => import('./pages/home/index'));
const PageLogin = lazy(() => import('./pages/login/index'));
const PageRegister = lazy(() => import('./pages/register/index'));
const PageForgetpassword = lazy(() => import('./pages/forgetpassword/index'));

const PageProfile = lazy(() => import('./pages/profile/index'));
const PageInvite = lazy(() => import('./pages/invite/index'));
const QrScanerDialog = lazy(() => import('./pages/qrscan/index'));
const PageApplogin = lazy(() => import('./pages/applogin/index'));



export function App() {
  const app = useAppManager();
  const modaler = useModalManager()
  const [check, setCheck] = useState(true);
  const [isDetectRTC, setDetectRTC] = useState(false);
  const [logined, setLogined] = useState(app.isAuthenticated);
  const checkLogin = async () => {
    try {
      let { displayname } = await app.apiMain.dti(Dtimain_loginBySession.meta).call({});
      if (displayname) {
        app.setSession({ displayname });

        let { count } = await app.apiMain.dti(Dtimain_Message.seeCount.meta).call({});
        app.SetMessageCount(count)
      }

    } catch (error) {
      // app.notifyManager.exception(error);
    }
    finally {
      setCheck(false)
    }
  }



  const opemQrScaner = () => {
    modaler.showModal(() => <ModalBasic centered >
      <Suspense fallback={<Loader box />}>
        <QrScanerDialog />
      </Suspense>
    </ModalBasic>)
  }


  useEffect(() => {
    checkLogin();

    let un = app.OpenQrScaner.subscribe(() => opemQrScaner())
    return () => un.unsubscribe();
  }, []);
  useEffect(() => {
    DetectRTC.load(() => {
      app.DetectRTC = DetectRTC;
      setDetectRTC(true)
    });
  }, []);

  useEffect(() => {
    let un = app.OnSessionChange.subscribe(() => {
      setLogined(app.isAuthenticated)
    });

    return () => un.unsubscribe();
  }, [])

  if (check || isDetectRTC === false) {
    return <Loader color="green" box />
  }

  return <Router >
    <Suspense fallback={<Loader box />}>
      <Switch >
        <Route exact path="/" component={PageHome} />
        <Route exact path="/login" component={PageLogin} />
        <Route exact path="/logout" component={PageLogout} />
        <Route exact path="/register" component={PageRegister} />
        <Route exact path="/forgetpassword" component={PageForgetpassword} />
        <Route exact path="/invite/:code" component={PageInvite} />
        <Route exact path="/applogin" component={PageApplogin} />
        <RoutePrivate isAuthenticated={logined} path="/profile" component={PageProfile} />
        {/*<RoutePrivate isAuthenticated={logined} path="/site/content" component={PageSiteContent} />
      <RoutePrivate isAuthenticated={logined} path="/site/settings" component={PageSiteSettings} />
      <RoutePrivate isAuthenticated={logined} path="/content-category" component={PageContentCategory} />
      <RoutePrivate isAuthenticated={logined} path="/member/list" component={PageMemberProfile} />
      <RoutePrivate isAuthenticated={logined} path="/member-category" component={PageMemberCategory} />
      <RoutePrivate isAuthenticated={logined} path="/member-request" component={PageMemberRequest} />
      <RoutePrivate isAuthenticated={logined} path="/report" component={PageReport} /> */}
        <Route component={Page404} />
      </Switch>
    </Suspense>
  </Router>
}