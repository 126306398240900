import { Dti } from "@napp/dti-core";


export namespace Dtimain_Message {

    export interface IMessageItem {
        id: string;
        type: number;
        data: Object;
        from_usar?: {id:string, name:string};
        from_app?: {id:string, name:string};

        subject: string;
        
        see?: boolean;
        see_at?: Date;
        read?: boolean;
        read_at?: Date;
        created_at: Date;
    }

    
    

    export namespace list {
        export interface Resu {
            items: IMessageItem[]
            unreaded:number
        }

        export const meta = Dti.define<Resu, void, void>({
            method: 'get',
            name: 'message-list'
        });
    }

    

    export namespace body {
        export interface Requ {
            id: string;
        }
        export interface Resu {
            body:string
        }

        export const meta = Dti.define<Resu, Requ, void>({
            method: 'get',
            name: 'message-body'
        });
    }


    export namespace remove {
        export interface Requ {
            id: string;
        }

        export interface Resu {
            flag: boolean
        }

        export const meta = Dti.define<Resu, void, Requ>({
            method: 'post',
            name: 'message-remove'
        });


    }

    export namespace read {
        export interface Requ {
            id: string;
        }

        export interface Resu {
            flag: boolean
        }

        export const meta = Dti.define<Resu, void, Requ>({
            method: 'post',
            name: 'message-read'
        });


    }

    export namespace seeCount {
        export interface Requ {
            
        }

        export interface Resu {
            count: number
        }

        export const meta = Dti.define<Resu, void, Requ>({
            method: 'get',
            name: 'message-see-count'
        });


    }
}

