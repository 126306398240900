import { DtiClientAdapter } from "@napp/dti-client";
import { Exception } from "@napp/exception";
import { DtiMode } from "@napp/dti-core";
export function DtiFactory(baseUrl: string) {

    return new DtiClientAdapter({
        caller: async (meta, { q, b }) => {

            let url = baseUrl + meta.path;
            if (q) {
                let m = meta.queryMode || DtiMode.QStr;
                if (m === DtiMode.QJson) {
                    url = url + '?p=' + encodeURIComponent(JSON.stringify(q))
                } else if (m === DtiMode.QStr) {
                    let ps: string[]  = []
                    for (let k of Object.keys(q)) {
                        ps.push(`${encodeURIComponent(k)}=${encodeURIComponent((q as any)[k] as any)}`)                        
                    }
                    url = url + '?' + ps.join('&');
                }
            }

            let body: any = undefined;
            if (meta.method === 'post' && b) {
                let bm = meta.bodyMode || DtiMode.BJson;                
                if (bm === DtiMode.BJson) {
                    body = JSON.stringify(b);
                } else if (bm === DtiMode.BForm) {
                    let formData = new FormData();
                    for (let k of Object.keys(b)) {
                        formData.append(k, (b as any)[k])
                    }
                    body = formData;
                }
            }

            let resp = await fetch(url, {
                method: meta.method,
                headers: {
                    "Content-Type": "application/json"
                },
                body
            });

            if (resp) {
                let respTxt = await resp.text();

                if (resp.ok) {
                    try {
                        let resu = JSON.parse(respTxt || '{}');
                        return resu;
                    } catch (error) {
                        throw new Exception("InvalidJSON", respTxt);
                    }
                }

                if (respTxt) {
                    try {
                        let error = JSON.parse(respTxt);
                        if (error && 'name' in error && 'message' in error) {
                            throw Exception.from(error);
                        }

                    } catch (error) {
                        throw Exception.from(error);
                    }
                }


            }

            console.error(resp);
            throw new Exception('NetworkError', '!!!!!!!!! error logic !!!!!!!!!!');
        }
    })
}