import {
    Route,
    RouteProps,
    Redirect
} from "react-router-dom";



interface ProtectedRouteProps extends RouteProps {
    isAuthenticated: boolean;
}
export function RoutePrivate({ path, isAuthenticated, exact, render, component, location }: ProtectedRouteProps) {
    if (!isAuthenticated) {
        return <Redirect to={{ pathname: '/login', state: { from: location } }} />
    }
    return <Route exact={exact} path={path} render={render} component={component} />
}