import React, { useEffect, useState } from 'react';
import css from './style.module.scss';
import { NotifyStore, INotifyStore, NotifyType } from './manager';

interface IProps {
    store: NotifyStore
}
export function NotifyContainer({ store }: IProps) {
    let [items, setItems] = useState(store.items)
    useEffect(() => {

        let un = store.onChange.subscribe(() => {
            setItems([...store.items]);
        });
        return () => un.unsubscribe();
    }, [store]);

    return <div className={css.container}>
        {items.map(it => {
            return <NotifyItem key={it.id} store={store} item={it} />
        })}
    </div>
}

interface IItemProps {
    item: INotifyStore

    store: NotifyStore
}



function findTypeClass(type: NotifyType) {
    if (type === 'error') return "is-danger"
    if (type === 'warn') return "is-warning"
    if (type === 'info') return "is-info"
    if (type === 'success') return "is-success"

    return '';
}

function NotifyItem({ item, store }: IItemProps) {

    let [state, setState] = useState({
        p: 10, mouse: false
    });


    useEffect(() => {
        let un = setInterval(() => {
            setState(({ p, mouse }) => {
                // console.log('--', p, mouse)
                if (p > 1) {
                    if (mouse === false) {
                        return { p: p - 1, mouse }
                    }
                } else {
                    store.remove(item.id)
                }
                return { p, mouse }
            });
        }, 1000);
        return () => clearInterval(un);
    }, [store]);


    let enter = () => setState(({ p }) => ({ p, mouse: true }));
    let leave = () => setState(({ p }) => ({ p, mouse: false }));


    if (item.title) {
        return <article className={`message ${findTypeClass(item.type)} ${css.item}`} onMouseEnter={() => enter()} onMouseLeave={() => leave()}>
            <div className="message-header">
                <p>{item.title}</p>
                {state.mouse
                    ? <button className="delete" aria-label="delete" onClick={e => store.remove(item.id)}></button>
                    : <small >{state.p}</small>
                }

            </div>
            <div className="message-body">
                <div>{item.message}</div>
            </div>

        </article>
    }
    return <article className={`message ${findTypeClass(item.type)} ${css.item}`} onMouseEnter={enter} onMouseLeave={leave}>
        <div className="is-pulled-right pr-2 pt-2">
            {state.mouse
                ? <button className="delete" aria-label="delete" onClick={e => store.remove(item.id)}></button>
                : <small>{state.p}</small>
            }
        </div>
        <div className="message-body">
            <div>{item.message}</div>
        </div>
    </article>
}

