
import './bootstrap';
import './style/index.scss';

import ReactDOM from 'react-dom';


import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { AppManager, AppManagerContext } from './manager';
import { NotifyContainer } from './comps/notify';
import { Exception, ExceptionParser } from '@napp/exception';
import { ModalProvider } from '@napp-ui/modal';

const app = new AppManager();


Exception.addParser((err) => {
  console.log('parser error', err)
  return ExceptionParser(err) || false
});



ReactDOM.render(
  <AppManagerContext.Provider value={app}>
    <ModalProvider>
      <App />
      <NotifyContainer store={app.notifyStore} />
    </ModalProvider>
  </AppManagerContext.Provider>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
