import { Dti } from "@napp/dti-core";

export namespace Dtimain_loginDoLogout {


    export interface Resu {
        flag: boolean;
    }

    export const meta = Dti.define<Resu, void, void>({
        name: 'login-do-logout', method: 'post',


    });
}