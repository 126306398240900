import { ReactNode } from "react";

interface IProps {
    buttons: ReactNode
}
export function LayHeaderPublic({ buttons }: IProps) {
    return <section className="p-4 bg-primary bg-opacity-75 text-white">
        <div className="container-md">
            <div className="row">
                <div className="col">
                    <h1 className="fs-2 mb-0"> Userly </h1>
                    <h2 className="fs-4 fw-light d-none d-md-block"> хэрэглэгчийн удирдлагын систем</h2>
                    <h2 className="fs-5 fw-light d-block d-md-none "> хэрэглэгчийн удирдлагын систем</h2>
                </div>
                <div className="col-sm-auto pt-3 pt-sm-0">
                    {buttons}
                </div>
            </div>
        </div>
    </section>
}