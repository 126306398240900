import React from 'react'
import { Subject } from 'rxjs'
import { uuid } from 'src/comm/uuid';

export type NotifyType = 'info' | 'warn' | 'error' | 'success';
export class NotifyManager {
    constructor(private store: NotifyStore) {

    }
    info(message: React.ReactNode, title?: React.ReactNode) {
        this.push({
            id: uuid(),
            message,
            type: 'info',
            title
        });
        return this;
    }
    success(message: React.ReactNode, title?: React.ReactNode) {
        this.push({
            id: uuid(),
            message,
            type: 'success',
            title
        });
        return this;
    }
    warn(message: React.ReactNode, title?: React.ReactNode) {
        this.push({
            id: uuid(),
            message,
            type: 'warn',
            title
        });
        return this;
    }
    error(message: React.ReactNode, title?: React.ReactNode) {
        this.push({
            id: uuid(),
            message,
            type: 'error',
            title
        });
        return this;
    }

    exception(err: any) {        
        this.push({
            id: uuid(),
            message: err.message || 'no message',
            type: 'error',
            title: err.name || 'error'
        });
        return this;
    }

    push(item: INotifyStore) {
        this.store.add(item);
        return this;
    }
}

export interface INotifyStore {
    id: string;
    type: NotifyType;
    title?: React.ReactNode;
    message: React.ReactNode
}

export class NotifyStore {
    items: Array<INotifyStore> = []

    onChange = new Subject<{ action: 'add' | 'remove', id: string }>();

    add(item: INotifyStore) {
        this.items.push(item);

        this.onChange.next({ action: 'add', id: item.id });
    }

    remove(id: string) {
        for (var i = 0; i < this.items.length; i++) {
            if (this.items[i].id === id) {
                this.items.splice(i, 1);
                this.onChange.next({ action: 'add', id: id });
                break;
            }
        }

        console.log('remove', this.items)
    }
}