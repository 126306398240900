import { useHistory } from "react-router-dom";
import { LayFooter } from "./lay.footer";
import { LayHeaderPublic } from "./lay.header.public";
import { Loader } from "../comps/loader";
import { useEffect } from "react";
import { useAppManager } from "src/manager";
import { Dtimain_loginDoLogout } from "src/gen/dti/main/login.do.logout";

export function PageLogout() {
    const app = useAppManager();
    const his = useHistory();
    useEffect(() => {

        (async () => {
            try {
                await app.apiMain.dti(Dtimain_loginDoLogout.meta).call({});
                app.delSession();

            } catch (error) {
                app.notifyManager.exception(error);
            } finally {
                his.push('/')
            }

        })();

    }, [])
    return <>
        <LayHeaderPublic buttons={<></>} />

        <div className="container">
            <Loader color="green" box />
        </div>
        <LayFooter />
    </>
}