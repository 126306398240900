export function LayFooter() {
    return <footer>
        <div className="bg-secondary py-4 bg-opacity-10">
            <div className="text-center">
                <p className="text-muted">
                    <strong>Userly</strong> хэрэглэгчийн удирдлагын систем. <br />
                    2013 - {new Date().getFullYear()} он
                </p>
            </div>
        </div>
    </footer>
}