import { Dti } from "@napp/dti-core";

export namespace Dtimain_loginBySession {
    export interface QParam {

    }
    export interface BParam {

    }

    export interface Resu {
        displayname: string;
    }

    export const meta = Dti.define<Resu, QParam, BParam>({
        name: 'login-by-session', method: 'post',

        checkB: (b) => {

        }
    });
}