
import React, { useContext } from "react";
import { Subject } from "rxjs";
import { DtiFactory } from "./api";
import DetectRTC from 'detectrtc';
import { NotifyManager, NotifyStore } from "./comps/notify";

export interface ISession {
    // userlyid: string;
    displayname: string;
}



export class AppManager {

    session: ISession | null = null;

    OnSessionChange = new Subject<void>();
    OnMessageCountChange = new Subject<number>();

    messageCount = 0;
    SetMessageCount (c:number){
        this.messageCount = c;
        this.OnMessageCountChange.next(c);
    }
    OpenQrScaner = new Subject<void>();

    setSession(sess:ISession) {
        this.session = sess;
        this.OnSessionChange.next();
    }

    delSession() {
        this.session = null;
        this.OnSessionChange.next();
    }

    get isAuthenticated() {
        if (this.session && this.session.displayname) {
            return true;
        }
        return false;
    }

    

    notifyStore = new NotifyStore();
    notifyManager = new NotifyManager(this.notifyStore);
    DetectRTC = DetectRTC;

    apiMain = DtiFactory('/api/v5/main');


    static instance = new AppManager();


    

}


export const AppManagerContext = React.createContext<AppManager | null>(null);

export const useAppManager = () => {
    let c = useContext(AppManagerContext);
    if (c === null) {
        throw new Error('not defined app manager')
    }
    return c;
};